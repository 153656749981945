import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  useMediaQuery,
} from '@mui/material';
import FieldHeader from '../../../components/FieldHeader';
import { theme } from '../../../theme';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { appColors } from '../../../theme/appColors';
import { useChangePasswordMutation, useGetUserQuery } from '../../posApi';
import { useNotifier } from '../../../core/Notifier';
import { IUserInfo } from '../../../core/Interface/api.interface';

interface IPassword {
  openModel?: boolean;
  title: string;
  handleCloseDialog: (close: boolean) => void;
}

const ChangePassword = ({
  title,
  openModel = false,
  handleCloseDialog,
}: IPassword) => {
  const { t } = useTranslation(['common', 'sales']);
  const [open] = React.useState(openModel);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { data } = useGetUserQuery();
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const { showErrorMessage, showMessage } = useNotifier();

  const handleClose = () => {
    handleCloseDialog(false);
  };

  const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Please enter your old password'),
    newPassword: Yup.string().required('Please enter your new password'),
    confirmPassword: Yup.string()
      .required('Please retype your password.')
      .oneOf([Yup.ref('newPassword')], 'Your passwords do not match.'),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
    },
    onSubmit: async (values) => {
      try {
        const temData = {
          userId: (data?.data as IUserInfo).id,
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        };

        const passwordResponse = await changePassword(temData).unwrap();
        if (!passwordResponse.status) {
          showErrorMessage(passwordResponse.data as string);
        } else {
          handleClose();
          showMessage('Password changed successfully');
        }
      } catch (error) {
        showErrorMessage('Something went wrong');
      }
    },
    validationSchema: ChangePasswordSchema,
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Dialog
          aria-describedby="alert-dialog-description"
          classes={{ paper: 'inherit' }}
          fullScreen={isMobile}
          fullWidth={isMobile}
          maxWidth="lg"
          open={open}
        >
          <DialogTitle
            sx={{
              fontWeight: 600,
              p: 3,
              backgroundColor: appColors.blueGreyishLight[30],
              borderRadius: '5px',
            }}
          >
            {title}
          </DialogTitle>
          <DialogContent sx={{ width: isMobile ? 'auto' : '40vw', p: 4 }}>
            <Box>
              <Grid container>
                <GridItem>
                  <FieldHeader title="Old Password" />
                  <TextField
                    autoComplete="off"
                    placeholder="Old Password"
                    size="small"
                    {...formik.getFieldProps('oldPassword')}
                    InputProps={{ inputProps: { min: 1 } }}
                    sx={{ height: `${theme.spacing(5)}`, width: '100%' }}
                    type="password"
                  />
                  {formik.errors.oldPassword && formik.touched.oldPassword ? (
                    <Box sx={{ color: appColors.carmineRed[100] }}>
                      {formik.errors.oldPassword}
                    </Box>
                  ) : null}
                </GridItem>
                <GridItem>
                  <FieldHeader title="New Password" />
                  <TextField
                    autoComplete="off"
                    placeholder="New Password"
                    size="small"
                    {...formik.getFieldProps('newPassword')}
                    InputProps={{ inputProps: { min: 1 } }}
                    sx={{ height: `${theme.spacing(5)}`, width: '100%' }}
                    type="password"
                  />
                  {formik.errors.newPassword && formik.touched.newPassword ? (
                    <Box sx={{ color: appColors.carmineRed[100] }}>
                      {formik.errors.newPassword}
                    </Box>
                  ) : null}
                </GridItem>
                <GridItem>
                  <FieldHeader title="Confirm Password" />
                  <TextField
                    autoComplete="off"
                    placeholder="Confirm Password"
                    size="small"
                    {...formik.getFieldProps('confirmPassword')}
                    InputProps={{ inputProps: { min: 1 } }}
                    sx={{ height: `${theme.spacing(5)}`, width: '100%' }}
                    type="password"
                  />
                  {formik.errors.confirmPassword ? (
                    <Box sx={{ color: appColors.carmineRed[100] }}>
                      {formik.errors.confirmPassword}
                    </Box>
                  ) : null}
                </GridItem>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ paddingRight: '24px', p: 4, py: 3, gap: 2 }}>
            <Button
              onClick={handleClose}
              sx={{
                width: 200,
                height: 42,
                borderRadius: '5px',
                '&:hover': {
                  // backgroundColor: appColors.carmineRed[20],
                },
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              autoFocus
              disabled={
                !formik.isValid ||
                Object.keys(formik.touched).length === 0 ||
                isLoading
              }
              onClick={() => {
                formik.handleSubmit();
              }}
              sx={{
                width: 200,
                height: 42,
                borderRadius: '5px',
              }}
              type="submit"
              variant="contained"
            >
              {isLoading && (
                <Box sx={{ pr: 2, pt: 0.5 }}>
                  <CircularProgress
                    size={16}
                    sx={{ color: 'white' }}
                  />
                </Box>
              )}
              Change
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default React.memo(ChangePassword);

const GridItem = ({ ...rest }) => {
  return (
    <Grid
      item
      key={1}
      lg={12}
      sm={12}
      xs={12}
      sx={{ pt: 2 }}
      {...rest}
    />
  );
};
