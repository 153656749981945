import React, { useMemo, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import { header } from '../../styles/header';
import Box from '@mui/material/Box';
import { useGetUserQuery } from '../../page/posApi';
import { IUserInfo } from '../../core/Interface/api.interface';
import {
  Avatar,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { appColors } from '../../theme/appColors';
import ProfilePopup from '../../components/ModelPopup/ProfilePopup';
import { keyframes, positions } from '@mui/system';

const marqueeAnimation = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
`;

const Header = ({ ...rest }) => {
  const { data: companyData } = useGetUserQuery();

  const companyName = useMemo(() => {
    if (companyData?.data) {
      const userData = companyData.data as IUserInfo;
      if (userData.company) {
        return userData.company.name;
      }
    }
    return false;
  }, [companyData]);
  const { data } = useGetUserQuery();
  const userName = useMemo(() => {
    if (data?.data) {
      const userData = data.data as IUserInfo;
      return userData.firstName;
    }
    return false;
  }, [data]);

  const branchName = useMemo(() => {
    if (data?.data) {
      const userData = data.data as IUserInfo;
      return userData.branch.name;
    }
    return '';
  }, [data]);
  const noticeText = useMemo(() => {
    if (data?.data) {
      const noticeTextData = data.data as IUserInfo;
      return noticeTextData.noticeText;
    }
    return '';
  }, [data]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [profile, setProfile] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfile(event.currentTarget);
  };

  const handleClose = () => {
    setProfile(null);
  };

  return (
    <Box
      sx={{
        ...header,
        ...(isSmall && {
          height: '56px',
          backgroundColor: 'white',
          '& header': { height: '56px', backgroundColor: 'white' },
        }),
      }}
      {...rest}
    >
      <AppBar
        elevation={10}
        position="fixed"
        sx={{ pl: { xs: 1, md: 32 }, pr: { xs: 1, md: 4 } }}
      >
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid
            item
            lg={8.5}
            md={8}
          >
            <Grid
              container
              justifyContent="space-between"
            >
              {!isSmall && (
                <Grid
                  item
                  lg={4}
                  md={5}
                  sm={6}
                  sx={{
                    fontSize: { xs: '0.6rem', sm: '1.4rem' },
                    pt: 2.5,
                    fontWeight: 800,
                    color: appColors.royalBlue[80],
                  }}
                >
                  {companyName}
                </Grid>
              )}

              <Grid
                item
                lg={7}
                md={7}
                sm={6}
              >
                {!isSmall && noticeText && (
                  <Box
                    sx={{
                      background: 'white',
                      overflow: 'hidden',
                      height: 60,
                      width: '120% ',
                      position: 'relative',
                      transform: 'translate(-50%, -50%)',
                      top: '45%',
                      left: '50%',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '0.9rem',
                        fontWeight: 700,
                        pt: 3,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        color: appColors.darkGreen[80],
                        borderRight: '0.2px solid white',
                        display: 'block',
                        position: 'absolute',
                        width: 'fit-content',
                        animation: `${marqueeAnimation} 60s linear infinite`,
                      }}
                    >
                      {noticeText}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            lg={3.5}
            md={4}
          >
            <Grid
              container
              data-testid="user-content"
              justifyContent="flex-end"
            >
              <Grid item>
                <Typography
                  sx={{
                    fontSize: isSmall ? '0.7rem' : '0.9rem',
                    fontWeight: isSmall ? 400 : 600,
                    pt: 2,
                  }}
                >
                  {userName}
                </Typography>
                {branchName && (
                  <Typography
                    sx={{
                      fontSize: isSmall ? '0.5rem' : '0.7rem',
                      fontWeight: 'normal',
                    }}
                  >
                    Branch : {branchName}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Tooltip
                  sx={{ fontSize: '14px', fontWeight: 'bold' }}
                  title="Open settings"
                >
                  <IconButton
                    onClick={handleClick}
                    sx={{
                      pt: 3,
                      borderRadius: '16px',
                      cursor: 'pointer',
                      height: '50px',
                      width: '50px',
                      fontWeight: 'bold',
                      '&:hover': {
                        backgroundColor: appColors.greyishBlue[40],
                      },
                    }}
                  >
                    <Avatar
                      sx={{
                        ...(isSmall && {
                          height: '20px',
                          width: '20px',
                        }),
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <ProfilePopup
              Branchname={branchName}
              Username={userName}
              onClose={handleClose}
              open={Boolean(profile)}
              profile={profile}
            />
          </Grid>
        </Grid>
        <Grid
          item
          lg={0.1}
        />
      </AppBar>
    </Box>
  );
};

export default React.memo(Header);
